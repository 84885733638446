import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/dashboard';
import EmployeeRegistrationForm from './components/registration_page';
import Profile from './components/profile';
import ResetPassword from './components/resetpasword'
import Signup from './components/signup';
import ForgotPassword from './components/forgotpassword';
import Finance from './components/finance';
import Userlogs from './components/userlogs';
import FinancialHistory from './components/finance_history';
import ActiveUsers from './components/ActiveUsers';
import FinancePage from './components/financepage';
import Reset from './components/reset';
import UsersList from './components/users_list';
import Example from './components/test2';



function App() {


  useEffect(() => {

  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<Login />}
          />
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/example"
            element={<Example />}
          />
          <Route
            path="/registration_page"
            element={<EmployeeRegistrationForm />}
            />
           <Route
            path="/ActiveUserList"
            element={<ActiveUsers />}
          />
          <Route
            path="/profile"
            element={<Profile />}
          />
          <Route
            path="/resetpassword"
            element={<ResetPassword />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard />}
          />
          <Route
            path="/SignUp"
            element={<Signup />}
          />
          <Route
            path='/forgotPassword'
            element={<ForgotPassword />}
          />
           <Route
            path='/financedetails'
            element={<FinancePage />}
          />
          <Route
            path='/finance'
            element={<Finance />}
          />
          <Route
            path='/userlogs'
            element={<Userlogs />}
          />
          <Route
            path='/finance_history'
            element={<FinancialHistory />}
          />

          <Route
          path='/reset'
          element={<Reset/>}
          />
          
          <Route
          path='/users_list'
          element={<UsersList/>}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
