import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/exflogo2.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const validEmailDomain = /@exafluence\.com$/;
            if (!validEmailDomain.test(username)) {
                setErrorMessage('Invalid email domain');
                setUsername('');
                setPassword('');
                return;
            }
            setIsLoading(true);

            const response = await axios.post(
                process.env.REACT_APP_IP + "api/auth/signin",
                {
                    email: username,
                    password: password,
                }
            );

            if (response.status === 200) {
                console.log('Login successful:', response.data.email);
                localStorage.setItem('Token', response.data.token);
                localStorage.setItem('Username', response.data.username);
                localStorage.setItem('Email', response.data.email);
                localStorage.setItem('Role', response.data.role);
                setErrorMessage('');
                const userRole = response.data.role[0]; // Get the first role
                console.log('User role:', userRole);  // Debugging statement

                if (userRole === 'ASSOCIATE_HR') {
                    navigate("/registration_page");
                } else if (userRole === 'ADMIN_FINANCE' || userRole === 'ASSOCIATE_FINANCE') {
                    navigate("/finance");
                } else {
                    navigate('/dashboard');
                }
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Login failed:', error);
            if (error.response && error.response.status === 429) {
                setErrorMessage('Too many reset attempts. Please try again after 24 hours.');
                setIsLoading(false);
                
        
            } else {
                setErrorMessage('Login failed. Invalid Username / password');
                setIsLoading(false);
               
            }
            
        }
    };

    const redirectToResetPage = () => {
        navigate('/forgotPassword');
    };

    useEffect(() => {
        const authToken = localStorage.getItem('Token');
        if (!authToken) {
            navigate('/login');
        }

        sessionStorage.clear();
        localStorage.clear();
        // localStorage.setItem("islogged", false);
    }, [navigate]);

    const logoStyle = {
        width: '20%',
        margin: 0,
        zIndex: 10,
        backgroundColor: 'transparent',
    };



    return (
        <div className="page-body">
            <nav className="navbar navbar-custom  navbar-expand-lg  bg-white ">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="Logo" style={logoStyle} />
                    </a>
                    <div>
                       
                    </div>
                </div>
            </nav>
            <div className="login-container card shadow-5-strong">

                <div className="card-body py-3 px-md-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <h2>
                                <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                                <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                                <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                            </h2>
                            {isLoading && (
                                <div className="loader">
                                    <div className="circular-loader"></div>
                                </div>
                            )}
                            {errorMessage && (
                                <div className="alert alert-danger" role="alert">
                                    {errorMessage}
                                </div>
                            )}
                            <form>
                                <div className="form-group">
                                    <input
                                        autoComplete='off'
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Enter email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        autoComplete='off'
                                        type={showPassword ? "text" : "password"}
                                        className="form-control mt-3"
                                        id="password"
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="form-check" style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="showPassword"
                                            checked={showPassword}
                                            onChange={() => setShowPassword(!showPassword)}
                                            style={{ marginRight: "5px" }}
                                        />
                                        <label className="form-check-label" htmlFor="showPassword" style={{ margin: "0", whiteSpace: "nowrap" }}>
                                            Show Password
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block mt-3 mb-4" onClick={handleLogin}>
                                    Login
                                </button>
                                <span className="reset-password" onClick={redirectToResetPage}>Forgot Password</span>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Login;
