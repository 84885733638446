import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './axios_instance';
import Modal from 'react-modal';
import { upload } from '@testing-library/user-event/dist/upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './register.css'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mantine/core';




const EmployeeRegistrationForm = () => {
    const defaultDomain = "@exafluence.com";
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        employeeId: '',
        employeeName: '',
        phoneNumber: '',
        gender: '',
        emailId: '',
        department: '',
        dateOfJoining: null,
        dateOfBirth: null,
        designation: '',
        location: '',
        totalExperience: '',
        educationalQualifications: '',
        workingType: '',
        onsiteOffshore: '',
        inductionOnboarding: false,
        primaryTechnicalskills: '',
        secondaryTechnicalskills : '',
        otherTechnicalskills : '',
        certifications: '',
        backgroundVerification: false,
        statutory: [],
        employeeStatus: '',
        status: '',
        salary: null,
        salaryDate: null,
        // annualCTC: 0.0,
        // monthlyCTC: 0.0,
        experienceUnit: 'year',
        otherQualification: '',
        otherlocation: '',
        otherDepartment: '',
        otherCountry: '',
        inActiveReason: '',
        level: '',
        country: 'India'
    });
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [bgDocerror, setBgDocError] = useState('');
    const [errorMessageDoc, setErrorMessageDoc] = useState('');
    const [previewImages, setPreviewImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [otherqualification, setOtherQualification] = useState('');
    const [otherlocation, setotherlocation] = useState('');
    const [otherDepartment, setotherDepartment] = useState('');
    const [otherCountry, setotherCountry] = useState('');
    const [inactivestatus, setinactivestatus] = useState('');

    const [resumeDetails, setResumeDetails] = useState('');
    const [ndaDetails, setNDADetails] = useState('');
    const [workingType, setWorkingType] = useState('');
    const [resumeFile, setResumeFile] = useState(null);
    // const [ndaFile, setNDAFile] = useState(null);

    const [profileFileSizeError, setProfileFileSizeError] = useState('');
    const [profileFileTypeError, setProfileFileTypeError] = useState('');
    const [resumeFileSizeError, setResumeFileSizeError] = useState('');
    const [resumeFileTypeError, setResumeFileTypeError] = useState('');
    const [NDAFileSizeError, setNDAFileSizeError] = useState('');
    ;
    const [PayslipFileTypeError, setPayslipFileTypeError] = useState('');
    const [PayslipFileSizeError, setPayslipFileSizeError] = useState('');
    const [NDAFileTypeError, setNDAFileTypeError] = useState('');
    const [NDAFile, setNDAFile] = useState(null);
    const [PayslipFile, setPayslipFile] = useState([]);
    // const [EduFile, setEduFile] = useState([]);
    const [EduFiles, setEduFiles] = useState([]);
    const [EduFileTypeError, setEduFileTypeError] = useState('')
    const [EduFileSizeError, setEduFileSizeError] = useState('');

    const [otherDocuments, setotherDocuments] = useState([]);
    const [otherDocumentsSizeError, setotherDocumentsSizeError] = useState('');
    const [otherDocumentsTypeError, setotherDocumentsTypeError] = useState('');
    const [resume, setResume] = useState(null);

    const [profileImage, setProfileImage] = useState(null);

    const [backgroundVerificationDoc, setBackgroundVerificationDoc] = useState(null);
    const [backgroundVerificationDocSizeError, setBackgroundVerificationDocSizeError] = useState('');
    const [backgroundVerificationDocTypeError, setBackgroundVerificationDocTypeError] = useState('');
    // Dubai
    const [workVisa, setWorkvisa] = useState([]);
    const [WorkvisaSizeError, setWorkvisaSizeError] = useState('');
    const [WorkvisaTypeError, setWorkvisaTypeError] = useState('');

    const [workPermit, setWorkPermit] = useState([]);
    const [WorkPermitSizeError, setWorkPermitSizeError] = useState('');
    const [WorkPermitTypeError, setWorkPermitTypeError] = useState('');

    const [residanceVisa, setResidanceVisa] = useState([]);
    const [ResidanceVisaSizeError, setResidanceVisaSizeError] = useState('');
    const [ResidanceVisaTypeError, setResidanceVisaTypeError] = useState('');

    const [emriatesID, setEmriatesID] = useState([]);
    const [EmriatesIDSizeError, setEmriatesIDSizeError] = useState('');
    const [EmriatesIDTypeError, setEmriatesIDTypeError] = useState('');

    const [labourCard, setLabourCard] = useState([]);
    const [LabourCardSizeError, setLabourCardSizeError] = useState('');
    const [labourCardTypeError, setLabourCardTypeError] = useState('');
    // canada
    const [gcCard, setgcCard] = useState([]);
    const [gcCardSizeError, setgcCardSizeError] = useState('');
    const [gcCardTypeError, setgcCardTypeError] = useState('');

    const [passportCopy, setpassportCopy] = useState([]);
    const [passportCopySizeError, setpassportCopySizeError] = useState('');
    const [passportCopyTypeError, setpassportCopyTypeError] = useState('');

    const [i_797, seti_797] = useState([]);
    const [i_797SizeError, seti_797SizeError] = useState('');
    const [i_797TypeError, seti_797TypeError] = useState('');

    const [relievingLetter, setrelievingLetter] = useState([]);
    const [relievingLetterSizeError, setrelievingLetterSizeError] = useState('');
    const [relievingLetterTypeError, setrelievingLetterTypeError] = useState('');

    const [i_94Card, seti_94Card] = useState([]);
    const [i_94CardSizeError, seti_94CardSizeError] = useState('');
    const [i_94CardTypeError, seti_94CardTypeError] = useState('');

    const [experienceLetter, setexperienceLetter] = useState([]);
    const [experienceLetterSizeError, setexperienceLetterSizeError] = useState('');
    const [experienceLetterTypeError, setexperienceLetterTypeError] = useState('');

    const [ssnNumber, setssnNumber] = useState([]);
    const [ssnNumberSizeError, setssnNumberSizeError] = useState('');
    const [ssnNumberTypeError, setssnNumberTypeError] = useState('');

    const [drivingLicense, setdrivingLicense] = useState([]);
    const [drivingLicenseSizeError, setdrivingLicenseSizeError] = useState('');
    const [drivingLicenseTypeError, setdrivingLicenseTypeError] = useState('');

    const [h4Copy, seth4Copy] = useState([]);
    const [h4CopySizeError, seth4CopySizeError] = useState('');
    const [h4CopySTypeError, seth4CopyTypeError] = useState('');


    // passportCopy,i_797,i_94Card,experienceLetter,relievingLetter,drivingLicense,ssnNumber,h4Copy,gcCard,otherDocuments

    const fileInputRefs = {
        backgroundVerificationDoc: useRef(null),
        nda: useRef(null),
        educationalDocuments: useRef(null),
        paySlips: useRef(null),
        profilePhoto: useRef(null),
        resume: useRef(null),
        otherDocuments: useRef(null),
        // dubai
        workVisa: useRef(null),
        workPermit: useRef(null),
        residanceVisa: useRef(null),
        emriatesID: useRef(null),
        labourCard: useRef(null),
        // USA
        gcCard: useRef(null),
        passportCopy: useRef(null),
        i_797: useRef(null),
        i_94Card: useRef(null),
        experienceLetter: useRef(null),
        relievingLetter: useRef(null),
        drivingLicense: useRef(null),
        ssnNumber: useRef(null),
        h4Copy: useRef(null),

    };

    const role = localStorage.getItem('Role');
    const isAdmin = role === 'ADMIN';
    const isSuperAdmin = role === 'SUPER_ADMIN';
    const isAdminFinanace = role === 'ADMIN_FINANCE';
    const isAssociateFinance = role === "ASSOCIATE_FINANCE";
    const isAssociateHR = role === "ASSOCIATE_HR";

    const [userName, setUserName] = useState();

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>/\\\-.+='`~[\];]/;
    const namePattern = /^[a-zA-Z\s]*$/; // Only letters and spaces are allowed
    const validateName = (value) => {
        if (!namePattern.test(value)) {
          console.warn('Only letters and spaces are allowed in employeeName');
          return false;
        }
        return true;
      };
    const validatePhoneNumber = (value) => {
      if (/\D/.test(value)) {
        console.warn('Only numbers are allowed in phoneNumber');
        return false;
      }
      if (value.length > 10) {
        console.warn('Phone number cannot exceed 10 digits');
        return false;
      }
      return true;
    };
  
    const validateSpecialChars = (name, value) => {
      if (specialCharPattern.test(value)) {
        console.warn(`Special characters are not allowed in ${name}`);
        return false;
      }
      return true;
    };
  
    const validateNegativeNumbers = (name, value) => {
      if (Number(value) < 0) {
        console.warn(`Negative numbers are not allowed for ${name}`);
        return false;
      }
      return true;
    };
  
    const validateTotalExperience = (value) => {
      const experiencePattern = /^\d+(\.\d+)?$/;
      if (!experiencePattern.test(value)) {
        console.warn('Invalid totalExperience format');
        return false;
      }
      return true;
    };
  
    const handleStatusChange = (name, value) => {
      if (value === "Inactive") {
        setWorkingType('');
        setEmployeeData(prevData => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        setEmployeeData(prevData => ({
          ...prevData,
          [name]: value,
        }));
      }
    };
  
    const handleListChange = (name, value) => {
      const valuesArray = value.split(',').map(item => item.trim());
      setEmployeeData(prevData => ({
        ...prevData,
        [name]: valuesArray,
      }));
    };
  
    const handleConditionalChange = (name, value, setOtherFunc) => {
      if (value === "Others" || value === "OTHERS") {
        setOtherFunc(value);
        setEmployeeData(prevData => ({
          ...prevData,
          [name]: "",
        }));
      } else {
        setOtherFunc(value);
        setEmployeeData(prevData => ({
          ...prevData,
          [name]: value,
        }));
      }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (['employeeId','designation'].includes(name) && !validateSpecialChars(name, value)) return;
        if (name === 'employeeName' && !validateName(value)) return;
        if (name === 'phoneNumber' && !validatePhoneNumber(value)) return;
        if (name === 'level' && !validateNegativeNumbers(name, value)) return;
        if (name === 'totalExperience' && !validateTotalExperience(value)) return;
        switch (name) {
          case 'status':
          case 'employeeStatus':
            handleStatusChange(name, value);
            break;
          case 'certifications':
          case 'primaryTechnicalskills':
            handleListChange(name, value);
            break;
          case 'secondaryTechnicalskills':
            handleListChange(name, value);
            break;
          case 'otherTechnicalskills':
            handleListChange(name, value);
            break;
          case 'salary':
          case 'gender':
            setEmployeeData(prevData => ({
              ...prevData,
              [name]: value,
            }));
            break;
          case 'workingType':
            setWorkingType(value);
            setEmployeeData(prevData => ({
              ...prevData,
              [name]: value,
            }));
            break;
          case 'country':
            handleConditionalChange(name, value, setotherCountry);
            break;
          case 'department':
            handleConditionalChange(name, value, setotherDepartment);
            break;
          case 'educationalQualifications':
            handleConditionalChange(name, value, setOtherQualification);
            break;
          case 'location':
            handleConditionalChange(name, value, setotherlocation);
            break;
          default:
            setEmployeeData(prevData => ({
              ...prevData,
              [name]: value,
            }));
        }
      };
    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     const specialCharPattern = /[!@#$%^&*(),.?":{}|<>/\\\-.+='`~[\];]/;
    //         if (name === 'phoneNumber') {
    //             if (/\D/.test(value)) {
    //               console.warn('Only numbers are allowed in phoneNumber');
    //               return;
    //             }
    //             if (value.length > 10) {
    //               console.warn('Phone number cannot exceed 10 digits');
    //               return;
    //             }
    //           }
              
    //     if (['employeeId', 'employeeName','designation'].includes(name) && specialCharPattern.test(value)) {
    //         console.warn(`Special characters are not allowed in ${name}`);
    //         return; 
    //     }
    //     if (name === 'phoneNumber' && /\D/.test(value)) {
    //         console.warn('Only numbers are allowed in phoneNumber');
    //         return; // Exit the function if non-numeric characters are found
    //     }
    //     if (name === 'level' && Number(value) < 0) {
    //         console.warn('Negative numbers are not allowed for level');
    //         return; 
    //     }
    //     if (name === 'totalExperience' && Number(value) < 0) {
    //         console.warn('Negative numbers are not allowed for level');
    //         return; 
    //     }
   
    //     if (name === 'status' || name === 'employeeStatus') {
    //         if (value == "Inactive") {
    //             setWorkingType('')
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: value
    //             }));
    //         }
    //         else {
    //             setEmployeeData({
    //                 ...employeeData,
    //                 [name]: value,
    //             });
    //         }

    //     }
    //     else if (name === 'certifications' || name === 'technicalskills') {
    //         const valuesArray = value.split(',').map((item) => item.trim());
    //         setEmployeeData({
    //             ...employeeData,
    //             [name]: valuesArray,
    //         });
    //     } else if (name === 'salary') {
    //         setEmployeeData({
    //             ...employeeData,
    //             [name]: value,
    //         });
    //     }
    //     else if (name == "workingType") {
    //         setWorkingType(value);
    //         setEmployeeData(prevData => ({
    //             ...prevData,
    //             [name]: value
    //         }))
    //     }
    //     else if (name == 'country') {
    //         console.log("country", value)
    //         if (value == "Others") {
    //             setotherCountry(value);
    //             console.log("otherCountry", otherCountry)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: ""
    //             }));
    //         }
    //         else {
    //             setotherCountry(value)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: value
    //             }));
    //         }
    //     }
    //     else if (name == 'gender') {
    //         setEmployeeData({
    //             ...employeeData,
    //             [name]: value,
    //         });
    //     }
    //     else if (name === 'totalExperience') {
    //         const [years, months] = value.split('.').map(Number);
    //         setEmployeeData({
    //             ...employeeData,
    //             totalExperience: value,
    //             experienceUnit: 'year',
    //         });
    //     } else if (name === 'department') {
    //         // setotherDepartment
    //         if (value == "OTHERS") {
    //             // setotherDepartment
    //             setotherDepartment(value);
    //             console.log("Otherdepartment", otherDepartment)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: ""
    //             }));
    //         }
    //         else {
    //             setotherDepartment(value)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: value
    //             }));
    //         }

    //     }
    //     else if (name === 'educationalQualifications') {
    //         if (value == "OTHERS") {
    //             setOtherQualification(value);
    //             console.log("OtherQuali", otherqualification)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: ""
    //             }));
    //         }
    //         else {
    //             setOtherQualification(value)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: value
    //             }));
    //         }


    //     }
    //     // const [inactivestatus, setinactivestatus] = useState('');
    //     else if (name === 'location') {
    //         if (value == "others") {
    //             setotherlocation(value);
    //             console.log("value in location",value)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: ""
    //             }));
    //         }
    //         else {
    //             setotherlocation(value)
    //             setEmployeeData(prevData => ({
    //                 ...prevData,
    //                 [name]: value
    //             }));
    //         }
    //         // otherlocation
    //     }
    //     else {
    //         setEmployeeData({
    //             ...employeeData,
    //             [name]: value,
    //         });
    //     }
    // };

    const handleLogout = () => {
        // Clear session data
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("islogged", false);
        window.location.href = "/";

        // Redirect to the login page (replace '/login' with your actual login route)
        // navigate('/login');

    }

    const calculateTotalExperience = () => {
        const { totalExperience, experienceUnit } = employeeData;
        return `${totalExperience} ${experienceUnit}`;
    };

    const handleUnitChange = (e) => {
        setEmployeeData({
            ...employeeData,
            experienceUnit: e.target.value,
        });
    };

    const openImageModal = (image) => {
        setSelectedImage(image);
    };

    const closeImageModal = () => {
        setSelectedImage(null);
    };

    const handleStatutoryChange = (e) => {
        const { name, checked } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            statutory: checked
                ? [...(prevData.statutory || []), name] // Add the option to the array
                : (prevData.statutory || []).filter((item) => item !== name), // Remove the option from the array
        }));
    };


    const handleDateChange = (date) => {
        setEmployeeData({
            ...employeeData,
            dateOfJoining: date || null, // Use an empty string if the date is cleared handleBirthDateChange
        });
    };

    const handleBirthDateChange = (date) => {
        setEmployeeData({
            ...employeeData,
            dateOfBirth: date || '', // Use an empty string if the date is cleared handleBirthDateChange
        });
    };

    const handleSalaryDateChange = (date) => {
        setEmployeeData({
            ...employeeData,
            salaryDate: date || '', // Use an empty string if the date is cleared handleBirthDateChange
        });
    };
    const documentFileTypes = [
        'image/png',
         'image/jpg', 
         'image/jpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    const handleFileSelect = (e, documentType) => {
        const files = e.target.files;

        // File type and size validation
        const selectedFile = e.target.files[0];
        const fileSizeLimit = 10 * 1024 * 1024; // 5MB in bytes
        if (selectedFile) {
            // Check file type and size for Profile Photo
            if (documentType === 'profilePhoto') {
                if (!['image/png', 'image/jpeg', 'image/jpg'].includes(selectedFile.type)) {
                    setProfileFileTypeError('Please upload a .png, .jpg, or .jpeg file');
                    setProfileFileSizeError('');
                    return;
                } else {
                    setProfileFileTypeError('');
                }

                const fileSizeLimit = 10 * 1024 * 1024; // 5MB in bytes
                if (selectedFile.size > fileSizeLimit) {
                    setProfileFileSizeError('Please upload a file below 10MB');
                    setProfileFileTypeError('');
                    return;
                } else {
                    setProfileFileSizeError('');
                }

                // Set profile image
                setProfileImage(URL.createObjectURL(selectedFile));
            }

            // Check file type and size for Resume
            if (documentType === 'resume') {
                if (!documentFileTypes.includes(selectedFile.type)) {
                    setResumeFileTypeError('Please upload a .pdf, .doc, or .docx file');
                    setResumeFileSizeError('');
                    return;
                } else {
                    setResumeFileTypeError('');
                }
                const fileSizeLimit = 10 * 1024 * 1024; // 5MB in bytes
                if (selectedFile.size > fileSizeLimit) {
                    setResumeFileSizeError('Please upload a file below 10MB');
                    setResumeFileTypeError('');
                    return;
                } else {
                    setResumeFileSizeError('');
                }

                // Set resume file
                setResume(selectedFile);
            }
            // Check file type and size for NDA
            if (documentType === 'nda') {
                if (!documentFileTypes.includes(selectedFile.type)) {
                    setNDAFileTypeError('Please upload a .pdf, .doc, or .docx file');
                    setNDAFileSizeError('');
                    return;
                } else {
                    setNDAFileTypeError('');
                }

                const fileSizeLimit = 10 * 1024 * 1024; // 5MB in bytes
                if (selectedFile.size > fileSizeLimit) {
                    setNDAFileSizeError('Please upload a file below 10MB');
                    setNDAFileTypeError('');
                    return;
                } else {
                    setNDAFileSizeError('');
                }

                // Replace existing NDA file
                setNDAFile(selectedFile);
            }
            if (documentType === 'paySlips') {
                if (!documentFileTypes.includes(selectedFile.type)) {
                    setPayslipFileTypeError('Please upload a .pdf, .doc, or .docx file');
                    setPayslipFileSizeError('');
                    return;
                } else {
                    setPayslipFileTypeError('');
                }

                const newFiles = Array.from(files).map((file) => {
                    if (file.size > fileSizeLimit) {
                        setPayslipFileSizeError('Please upload files below 10MB.');
                        return null;
                    }
                    return file;
                }).filter((file) => file !== null);

                // If EduFiles is null or undefined, initialize it as an empty array
                const updatedEduFiles = PayslipFile ? [...PayslipFile, ...newFiles] : [...newFiles];

                // Set the updated EduFiles
                setPayslipFile(updatedEduFiles);


            }

            if (documentType === 'otherDocuments') {
                const newFiles = Array.from(files).map((file) => {
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setotherDocumentsTypeError('Please upload a .pdf, .doc, or .docx file');
                        setotherDocumentsSizeError('');
                        return;
                    }
                    if (file.size > fileSizeLimit) {
                        setotherDocumentsSizeError('Please upload files below 10MB.');
                        return null;
                    }
                    return file;
                }).filter((file) => file !== null);

                // If EduFiles is null or undefined, initialize it as an empty array
                const updatedEduFiles = otherDocuments ? [...otherDocuments, ...newFiles] : [...newFiles];

                // Set the updated EduFiles
                setotherDocuments(updatedEduFiles);
            }


            if (documentType === 'educationalDocuments') {
                if (!documentFileTypes.includes(selectedFile.type)) {
                    setEduFileTypeError('Please upload a .pdf, .doc, or .docx file');
                    setEduFileSizeError('');
                    return;
                } else {
                    setEduFileTypeError('');
                }
                const newFiles = Array.from(files).map((file) => {
                    if (file.size > fileSizeLimit) {
                        setEduFileSizeError('Please upload files below 10MB.');
                        return null;
                    }
                    return file;
                }).filter((file) => file !== null);

                // If EduFiles is null or undefined, initialize it as an empty array
                const updatedEduFiles = EduFiles ? [...EduFiles, ...newFiles] : [...newFiles];

                // Set the updated EduFiles
                setEduFiles(updatedEduFiles);
            }

            // work visa
            if (documentType === 'workVisa') {
                const fileSizeLimit = 10 * 1024 * 1024; // 5MB in bytes
                if (selectedFile.size > fileSizeLimit) {
                    setWorkvisaSizeError('Please upload a file below 10MB');
                    setWorkvisa(null);
                } else {
                    setWorkvisaSizeError('');
                    setWorkvisa(selectedFile);
                }
            }

            switch (documentType) {
                case 'workVisa':
                    console.log("selectedFile.type",selectedFile.type)
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setEduFileTypeError('Please upload a .pdf, .doc, or .docx file');
                        setEduFileSizeError('');
                        return;
                    } else {
                        setEduFileTypeError('');
                    }
                    const newFiles = Array.from(files).map((file) => {
                        if (file.size > fileSizeLimit) {
                            setEduFileSizeError('Please upload files below 10MB.');
                            return null;
                        }
                        return file;
                    }).filter((file) => file !== null);

                    // If EduFiles is null or undefined, initialize it as an empty array
                    const updatedworkviseFiles = workVisa ? [...workVisa, ...newFiles] : [...newFiles];

                    console.log("work data check", updatedworkviseFiles)

                    // Set the updated EduFiles
                    setWorkvisa(updatedworkviseFiles);
                    break;

                case 'workPermit':
                    const newFiles1 = Array.from(files).map((file) => {
                        if (!documentFileTypes.includes(selectedFile.type)) {
                            setWorkPermitTypeError('Please upload a .pdf, .doc, or .docx file');
                            setWorkPermitSizeError('');
                            return;
                        }
                        if (file.size > fileSizeLimit) {
                            setWorkPermitSizeError('Please upload files below 10MB.');
                            return null;
                        }
                        return file;
                    }).filter((file) => file !== null);

                    // If EduFiles is null or undefined, initialize it as an empty array
                    const updatedEduFiles = workPermit ? [...workPermit, ...newFiles1] : [...newFiles1];

                    // console.log("work data check",updatedEduFiles)

                    // Set the updated EduFiles
                    setWorkPermit(updatedEduFiles);
                    break;

                case 'residanceVisa':
                    const newFiles2 = Array.from(files).map((file) => {
                        if (!documentFileTypes.includes(selectedFile.type)) {
                            setResidanceVisaTypeError('Please upload a .pdf, .doc, or .docx file');
                            setResidanceVisaSizeError('');
                            return;
                        }
                        if (file.size > fileSizeLimit) {
                            setResidanceVisaSizeError('Please upload files below 10MB.');
                            return null;
                        }
                        return file;
                    }).filter((file) => file !== null);

                    // If EduFiles is null or undefined, initialize it as an empty array
                    const updatedresFiles = residanceVisa ? [...residanceVisa, ...newFiles2] : [...newFiles2];

                    // console.log("work data check",updatedEduFiles)

                    // Set the updated EduFiles
                    setResidanceVisa(updatedresFiles);
                    break;

                case 'emriatesID':
                    const newFiles3 = Array.from(files).map((file) => {
                        if (!documentFileTypes.includes(selectedFile.type)) {
                            setEmriatesIDTypeError('Please upload a .pdf, .doc, or .docx file');
                            setEmriatesIDSizeError('');
                            return;
                        }
                        if (file.size > fileSizeLimit) {
                            setEmriatesIDSizeError('Please upload files below 10MB.');
                            return null;
                        }
                        return file;
                    }).filter((file) => file !== null);

                    // If EduFiles is null or undefined, initialize it as an empty array
                    const updatedemriatesIdFiles = emriatesID ? [...emriatesID, ...newFiles3] : [...newFiles3];

                    // console.log("work data check",updatedEduFiles)

                    // Set the updated EduFiles
                    setEmriatesID(updatedemriatesIdFiles);
                    break;

                case 'labourCard':
                    const newFiles4 = Array.from(files).map((file) => {
                        if (!documentFileTypes.includes(selectedFile.type)) {
                            setLabourCardTypeError('Please upload a .pdf, .doc, or .docx file');
                            setLabourCardSizeError('');
                            return;
                        }
                        if (file.size > fileSizeLimit) {
                            setLabourCardSizeError('Please upload files below 10MB.');
                            return null;
                        }
                        return file;
                    }).filter((file) => file !== null);

                    // If EduFiles is null or undefined, initialize it as an empty array
                    const updatedlabourCardFiles = labourCard ? [...labourCard, ...newFiles4] : [...newFiles4];

                    // console.log("work data check",updatedEduFiles)

                    // Set the updated EduFiles
                    setLabourCard(updatedlabourCardFiles);
                    break;


                case 'gcCard':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setgcCardTypeError('Please upload a .pdf, .doc, or .docx file');
                        setgcCardSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setgcCardSizeError('Please upload a file below 10MB');
                        setgcCard(null);
                    } else {
                        setgcCardSizeError('');
                        setgcCard(selectedFile);
                    }
                    break;
                // passportCopy,i_797,i_94Card,experienceLetter,relievingLetter,drivingLicense,ssnNumber,h4Copy,gcCard,otherDocuments
                case 'passportCopy':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setpassportCopyTypeError('Please upload a .pdf, .doc, or .docx file');
                        setpassportCopySizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setpassportCopySizeError('Please upload a file below 10MB');
                        setpassportCopy(null);
                    } else {
                        setpassportCopySizeError('');
                        setpassportCopy(selectedFile);
                    }
                    break;
                case 'i_797':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        seti_797TypeError('Please upload a .pdf, .doc, or .docx file');
                        seti_797SizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        seti_797SizeError('Please upload a file below 10MB');
                        seti_797(null);
                    } else {
                        seti_797SizeError('');
                        seti_797(selectedFile);
                    }
                    break;
                case 'i_94Card':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        seti_94CardTypeError('Please upload a .pdf, .doc, or .docx file');
                        seti_94CardSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        seti_94CardSizeError('Please upload a file below 10MB');
                        seti_94Card(null);
                    } else {
                        seti_94CardSizeError('');
                        seti_94Card(selectedFile);
                    }
                    break;
                case 'experienceLetter':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setexperienceLetterTypeError('Please upload a .pdf, .doc, or .docx file');
                        setexperienceLetterSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setexperienceLetterSizeError('Please upload a file below 10MB');
                        setexperienceLetter(null);
                    } else {
                        setexperienceLetterSizeError('');
                        setexperienceLetter(selectedFile);
                    }
                    break;

                case 'relievingLetter':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setrelievingLetterTypeError('Please upload a .pdf, .doc, or .docx file');
                        setrelievingLetterSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setrelievingLetterSizeError('Please upload a file below 10MB');
                        setrelievingLetter(null);
                    } else {
                        setrelievingLetterSizeError('');
                        setrelievingLetter(selectedFile);
                    }
                    break;
                case 'drivingLicense':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setdrivingLicenseTypeError('Please upload a .pdf, .doc, or .docx file');
                        setdrivingLicenseSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setdrivingLicenseSizeError('Please upload a file below 10MB');
                        setdrivingLicense(null);
                    } else {
                        setdrivingLicenseSizeError('');
                        setdrivingLicense(selectedFile);
                    }
                    break;

                // ssnNumber,h4Copy,otherDocuments
                case 'ssnNumber':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setssnNumberTypeError('Please upload a .pdf, .doc, or .docx file');
                        setssnNumberSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setssnNumberSizeError('Please upload a file below 10MB');
                        setssnNumber(null);
                    } else {
                        setssnNumberSizeError('');
                        setssnNumber(selectedFile);
                    }
                    break;

                case 'h4Copy':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        seth4CopyTypeError('Please upload a .pdf, .doc, or .docx file');
                        seth4CopySizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        seth4CopySizeError('Please upload a file below 10MB');
                        seth4Copy(null);
                    } else {
                        seth4CopySizeError('');
                        seth4Copy(selectedFile);
                    }
                    break;
                case 'backgroundVerificationDoc':
                    if (!documentFileTypes.includes(selectedFile.type)) {
                        setBackgroundVerificationDocTypeError('Please upload a .pdf, .doc, or .docx file');
                        setBackgroundVerificationDocSizeError('');
                        return;
                    }
                    if (selectedFile.size > fileSizeLimit) {
                        setBackgroundVerificationDocSizeError('Please upload a file below 10MB');
                        setBackgroundVerificationDoc(null);
                    } else {
                        setBackgroundVerificationDocSizeError('');
                        setBackgroundVerificationDoc(selectedFile);
                    }
                    break;



                default:
                    break;
            }


        }

        // Process valid files
        const newPreviewImages = Array.from(files).map((file) => {
            const id = Date.now();
            const preview = URL.createObjectURL(file);
            return { id, file, preview, documentType };
        });

        setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
    };



    // const handleEmailChange = (e) => {
    //     let value = e.target.value;
    //     // Remove the default domain from the value
    //     if (value.includes(defaultDomain)) {
    //         value = value.replace(defaultDomain, '');
    //     }
    //     // Add the default domain if value is not empty
    //     const updatedValue = value ? value + defaultDomain : "";

    //     // Only update state if the new value is different
    //     if (updatedValue !== employeeData.emailId) {
    //         setEmployeeData({
    //             ...employeeData,
    //             emailId: updatedValue
    //         });
    //     }
    // };

    // const handleDelete = (id) => {
    //     setPreviewImages((prevImages) => prevImages.filter((image) => image.id !== id));
    // };
    const handleDelete = (index, documentType) => {
        // Filter out the image with the provided index
        const updatedImages = previewImages.filter((image, idx) => idx !== index);
        setPreviewImages(updatedImages);

        switch (documentType) {
            case 'educationalDocuments':
                const updatedEduFiles = EduFiles.filter((file, idx) => idx !== index);
                setEduFiles(updatedEduFiles);
                break;
            case 'paySlips':
                const updatedPaySlipFiles = PayslipFile.filter((file, idx) => idx !== index);
                setPayslipFile(updatedPaySlipFiles);
                break;
            case 'otherDocuments':
                const updatedOtherDocuments = otherDocuments.filter((file, idx) => idx !== index);
                setotherDocuments(updatedOtherDocuments);
            case 'workVisa':
                const updateworkVisaFiles = workVisa.filter((file, idx) => idx !== index);
                setWorkvisa(updateworkVisaFiles);
            case 'workPermit':
                const updateworkPermitFiles = workPermit.filter((file, idx) => idx !== index);
                setWorkPermit(updateworkPermitFiles);
            case 'residanceVisa':
                const updateresidanceFiles = residanceVisa.filter((file, idx) => idx !== index);
                setResidanceVisa(updateresidanceFiles);
            case 'emriatesID':
                const updateemiratesFiles = emriatesID.filter((file, idx) => idx !== index);
                setEmriatesID(updateemiratesFiles);
            case 'labourCard':
                const updatelabourCardFiles = labourCard.filter((file, idx) => idx !== index);
                setLabourCard(updatelabourCardFiles);
            default:
                break;

        }

        // Reset file input
        const fileInput = fileInputRefs[documentType];
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const handleDelete1 = (id) => {
        setPreviewImages((prevImages) => {
            // Use the filter function to remove the image with the specified id
            return prevImages.filter((image) => image.id !== id);

        });
    };

    // const uploadClick = (documentType) => {
    //     fileInputRefs[documentType].current.click();
    // };
    const uploadClick = (refName) => {
        if (fileInputRefs[refName] && fileInputRefs[refName].current) {
            fileInputRefs[refName].current.click();
        }
    };


    const handleSubmit = async (e) => {

        e.preventDefault();
        // Add your logic to submit the form data
        try {
            setIsLoading(true)
            console.log("employeeData", employeeData)
            const validEmailDomain = /@exafluence\.com$/;
            console.log("employeeData.emailId", employeeData.emailId)
            if (!validEmailDomain.test(employeeData.emailId)) {
                setErrorMessage('Invalid email domain ');
                return;
            }

            console.log("employeeData", employeeData)
            console.log("Token", localStorage.getItem('Token'))
            // Prepare the form data
            const formData = new FormData();
            Object.entries(employeeData).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((item) => formData.append(key, item));
                } else if (key === 'dateOfJoining' || key === 'dateOfBirth' || key === 'salaryDate') {
                    formData.append(key, value ? new Date(value).toISOString() : null);

                } else {
                    formData.append(key, value);
                }
            });


            // Append the files to the form data
            previewImages.forEach((image) => {
                formData.append(image.documentType, image.file);
            });
            console.log(JSON.stringify(formData))
            // Replace 'YOUR_API_ENDPOINT' with the actual endpoint for user authentication
            const response = await axios.post(
                process.env.REACT_APP_IP + "api/createEmployee",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('Token')}`,
                    },
                }
            );
            console.log('Submitted Data:', employeeData);
            setIsLoading(false)
            setPreviewImages([]);
            setErrorMessage('');
            toast.success("Form Submittion successfully ", { position: toast.POSITION.TOP_CENTER });
            navigate('/dashboard')
            // {
            //     isAssociateHR ? (
            //         window.location.reload()
            //     ) : (
            //         navigate('/dashboard')
            //     )
            // }



        } catch (error) {
            if (error.message === 401) {
                setIsLoading(false)
                toast.error("Token Expired .......Please Login again....!", { position: toast.POSITION.TOP_CENTER })
                sessionStorage.clear();
                localStorage.clear();
                localStorage.setItem("islogged", false);
                navigate("/login")
            } else {
                setIsLoading(false)
                console.error('Registration:', error);
                toast.error("Form Submittion Failure ", { position: toast.POSITION.TOP_CENTER });
            }


        }

    };


    const handleReset = () => {
        window.location.reload();
    }

    const handleBack = () => {
        navigate("/dashboard")
    }

    useEffect(() => {
        const authToken = localStorage.getItem('Token');
        // Perform authentication logic with the authToken
        // Redirect to login if token is not present or invalid
        if (!authToken) {
            navigate('/login'); // You might want to redirect to the login page
        }

        if(!(isAdmin||isAssociateHR||isSuperAdmin)){
            navigate('/login');
        }
        

        const user = localStorage.getItem('Username');
        setUserName(user)
    }, []);
    function onQualificationChange(event) {
        const { name, value } = event.target;
        setEmployeeData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    function onStatusChange(event) {
        const { name, value } = event.target;
        setEmployeeData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }


    function onLocationChange(event) {
        const { name, value } = event.target;
        setEmployeeData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    function onDepartmentChange(event) {
        const { name, value } = event.target;
        setEmployeeData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    function onCountryChange(event) {
        const { name, value } = event.target;
        console.log("name", name, "value", value)

        setEmployeeData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    const handleResetPassword = () => {
        navigate("/reset")
    }
    return (
        <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>

            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>

                    {/* {isAssociateHR ? ( */}
                    <div className="d-flex align-items-center">
                        <button className="btn btn-primary text-white" style={{ marginRight: "1rem" }} onClick={handleBack}>
                            Back To Dashboard
                        </button>
                        <div className='' style={{ position: "relative", marginRight: "1rem" }} onClick={handleToggleDropdown}>
                            <FontAwesomeIcon icon={faCircleUser} size="3x" color='#228be6' />
                            {isDropdownOpen && (
                                <div className='d-profile' style={{ position: "absolute", top: "100%", right: 0 }}>
                                    <h6 className="m-1">{userName}</h6>
                                    <span className="reset-password" onClick={handleResetPassword}>Reset password</span>
                                    <Button onClick={handleLogout}>Logout</Button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* ) : (
                        <button className="btn btn-primary text-white" onClick={handleBack}>
                            Back To Dashboard
                        </button>
                    )} */}


                </div>
            </nav>
            <div className="container mt-3">
                <div className="card " style={{ boxShadow: "0px 0px 5px #000", borderRadius: "5px" }}>
                    <div className="card-body" style={{ textAlign: 'left' }}>
                        <div className="text-center pt-4  pb-4 bg-primary text-white " style={{ borderRadius: "5px" }}>
                            <h3>Employee Registration Form</h3>
                        </div>
                        {errorMessage && (
                            <div style={{ color: "red" }}>
                                {errorMessage}
                            </div>
                        )}
                        {bgDocerror && (
                            <div style={{ color: "red" }}>
                                {bgDocerror}
                            </div>
                        )}

                        <div className='mt-5 text-left '>
                            {isLoading && (
                                <div className="loader">
                                    <div className="circular-loader"></div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit} >
                                {/* country based on that registration form will chnage */}

                                <div className='col-md-3 mb-3'>
                                    <label htmlFor="country">Country <span style={{ color: 'red' }}>*</span></label>
                                    <select
                                        className="form-control"
                                        class="form-select"
                                        id="country"
                                        name="country"
                                        value={otherCountry}
                                        onChange={handleInputChange}
                                        required
                                    >

                                        <option value="India">India</option>
                                        <option value="Canada">Canada</option>
                                        <option value="US">US</option>
                                        <option value="Dubai">Dubai</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {/* Display a text box if the selected country is "OTHERS" */}
                                    {otherCountry === "Others" && (
                                        <div>
                                            <label htmlFor="othercountry">Enter Other Country<span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                className="form-control"
                                                id="country"
                                                name="country"
                                                value={employeeData.country || ""}
                                                onChange={onCountryChange} />
                                        </div>

                                    )}
                                </div>

                                {/* Employee ID */}
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <label className="text-left" htmlFor="employeeId">Employee ID <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"

                                            type="text"
                                            className="form-control"
                                            id="employeeId"
                                            name="employeeId"
                                            value={employeeData.employeeId}
                                            onChange={handleInputChange}
                                            placeholder="Enter Employee ID"
                                            required
                                        />
                                    </div>

                                    {/* Employee Name */}
                                    <div className="col-md-6">
                                        <label htmlFor="employeeName">Employee Name <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"

                                            type="text"
                                            className="form-control"
                                            id="employeeName"
                                            name="employeeName"
                                            value={employeeData.employeeName}
                                            onChange={handleInputChange}
                                            placeholder="Enter Employee Name"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    {/* Gender */}
                                    <div className="col-md-6">
                                        <label htmlFor="gender" >Gender <span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            className="form-select"
                                            id="gender"
                                            name="gender"
                                            value={employeeData.gender}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    {/* phone Number */}
                                    <div className="col-md-6">
                                        <label className="text-left" htmlFor="phoneNumber">Phone Number <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"

                                            type="tel"
                                            className="form-control"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={employeeData.phoneNumber}
                                            onChange={handleInputChange}
                                            placeholder="Phone Number"
                                            required
                                        />
                                    </div>


                                </div>

                                {/* Employee Email */}
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <label htmlFor="emailId">Email ID <span style={{ color: 'red' }}>*</span></label>

                                        <div className="input-group">
                                            <input autocomplete="off"

                                                type="text"
                                                className="form-control"
                                                id="emailId"
                                                name="emailId"
                                                value={employeeData.emailId}
                                                onChange={handleInputChange}
                                                placeholder="Enter username"
                                                required
                                            />
                                            {/* <div className="input-group-append">
                                                <span className="input-group-text">@exafluence.com</span>
                                            </div> */}
                                        </div>
                                    </div>

                                    {/* department */}

                                    <div className='col-md-6'>
                                        <label htmlFor="department">Department <span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            className="form-select"
                                            id="department"
                                            name="department"
                                            value={otherDepartment}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select department</option>
                                            <option value="IT">IT</option>
                                            <option value="HR">HR</option>
                                            <option value="INFRA">INFRA</option>
                                            <option value="SALES">SALES</option>
                                            <option value="FINANCE">FINANCE</option>
                                            <option value="MANAGEMENT">MANAGEMENT</option>
                                            <option value="BPOOPERATIONS">BPO OPERATIONS</option>
                                            <option value="OTHERS">OTHERS</option>
                                        </select>
                                        {/* Display a text box if the selected department is "OTHERS" */}
                                        {otherDepartment === "OTHERS" && (
                                            <div>
                                                <label htmlFor="otherDepartment">Enter Other Department</label>
                                                <input autocomplete="off"

                                                    type="text"
                                                    className="form-control"
                                                    id="department"
                                                    name="department"
                                                    value={employeeData.department || ""}
                                                    onChange={onDepartmentChange}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Designation */}
                                <div className="row mb-4">
                                    <div className='col-md-6'>
                                        <label htmlFor="designation">Designation <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"

                                            type="text"
                                            className="form-control"
                                            id="designation"
                                            name="designation"
                                            value={employeeData.designation}
                                            onChange={handleInputChange}
                                            placeholder="Enter Employee designation"
                                            required
                                        />
                                    </div>

                                    {/* Date of Joining */}
                                    <div className="col-md-3">
                                        <label htmlFor="dateOfJoining" >
                                            Date of Joining <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <div>
                                            <DatePicker
                                                selected={employeeData.dateOfJoining}
                                                onChange={(date) => handleDateChange(date)}
                                                dateFormat="yyyy-MM-dd"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                            />

                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="dateOfBirth" >
                                            Date of Birth <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <div>
                                            <DatePicker
                                                selected={employeeData.dateOfBirth}
                                                onChange={(date) => handleBirthDateChange(date)}
                                                dateFormat="yyyy-MM-dd"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                            />

                                        </div>

                                    </div>

                                    {/* CTC */}

                                </div>



                                {/* location */}
                                <div className="row mb-4">
                                    <div className='col-md-6'>
                                        <label htmlFor="location">Location <span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            className="form-select"
                                            id="location"
                                            name="location"
                                            value={employeeData.location}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            {console.log("location pre check", employeeData.location)}
                                            {console.log(otherCountry)}
                                            {console.log("employeeData.country",employeeData.country)}
                                            {employeeData.country === 'India' &&
                                                <>
                                                    <option value="">Select Location</option>
                                                    <option value="tirupati">Tirupati</option>
                                                    <option value="bangalore">Bangalore</option>
                                                    <option value="chennai">Chennai</option>
                                                    <option value="hyderabad">Hyderabad</option>
                                                    <option value="gulburga">Gulburga</option>
                                                    <option value="others">Others</option>
                                                </>
                                            }
                                            {employeeData.country === 'Canada' &&
                                                <>
                                                    <option value="">Select Location</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="others">Others</option>
                                                </>
                                            }
                                            {employeeData.country === 'Dubai' &&
                                                <>
                                                    <option value="">Select Location</option>
                                                    <option value="Dubai">Dubai</option>
                                                    <option value="others">Others</option>
                                                </>
                                            }
                                            {employeeData.country === 'US' &&
                                                <>
                                                    <option value="">Select Location</option>
                                                    <option value="US">US</option>
                                                    <option value="others">Others</option>
                                                </>
                                            }
                                            {otherCountry === "Others" &&
                                                <>
                                                    <option value="">Select Location</option>
                                                    <option value="others">Others</option>
                                                </>

                                            }
                                            {/* {employeeData.country === 'Others' &&
                                                <>

                                                    <option value="others">Others</option>
                                                </>
                                            } */}

                                        </select>
                                        {/* Display a text box if the selected location is "OTHERS" */}

                                        {otherlocation === "others" && (
                                            <div>
                                                <label htmlFor="otherlocation">Enter Other Location<span style={{ color: 'red' }}>*</span></label>
                                                <input autocomplete="off"

                                                    type="text"
                                                    className="form-control"
                                                    id="location"
                                                    name="location"
                                                    value={employeeData.location || ""}
                                                    onChange={onLocationChange}
                                                // onChange={handleInputChange}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {/* totalExperience */}

                                    <div className="col-md-3">
                                        <label htmlFor="totalExperience">Experience  <span style={{ color: 'red' }}>*</span></label>
                                        <input autocomplete="off"

                                            type="number"
                                            className="form-control"
                                            id="totalExperience"
                                            name="totalExperience"
                                            value={employeeData.totalExperience}
                                            onChange={handleInputChange}
                                            placeholder="Employee totalExperience"
                                        />
                                    </div>



                                </div>
                               
                                {/* Educational Qualification */}
                                <div className='row'>
                                    {/* Level */}
                                    <div className='col-md-6'>
                                        <label htmlFor="level">Level </label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="level"
                                            name="level"
                                            value={employeeData.level}
                                            onChange={handleInputChange}
                                            placeholder="Enter Employee level"
                                            
                                        />
                                    </div>
                                    <div className="col-md-6 mb-4">

                                        <div className="row mb-4">
                                            <div className='col-md-6'>
                                                <label htmlFor="educationalQualifications">Educational Qualification <span style={{ color: 'red' }}>*</span></label>
                                                <select
                                                    className="form-control"
                                                    class="form-select"
                                                    id="qualification"
                                                    name="educationalQualifications"
                                                    value={otherqualification}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value="">Select Qualification</option>
                                                    <option value="BTECH">B.Tech</option>
                                                    <option value="MCA">MCA</option>
                                                    <option value="OTHERS">OTHERS</option>
                                                </select>
                                            </div>

                                            {/* totalExperience */}

                                            {otherqualification === "OTHERS" && (
                                                <div className="col-md-3">
                                                    <label htmlFor="totalExperience">Qualification<span style={{ color: 'red' }}>*</span></label>
                                                    <input autocomplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        id="qualificationname"
                                                        name="educationalQualifications"
                                                        value={employeeData.educationalQualifications || ""}
                                                        onChange={onQualificationChange}
                                                        placeholder="Enter Qualification"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* Technologies Known */}
                                <div className="row mb-4">
                                    <div className='col-md-6'>
                                        <label htmlFor="primaryTechnicalskills">Primary Technical Skills</label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="primaryTechnicalskills"
                                            name="primaryTechnicalskills"
                                            value={employeeData.primaryTechnicalskills}
                                            onChange={handleInputChange}
                                            placeholder="Primary Technologies"

                                        />
                                    </div>

                                    <div className='col-md-6'>
                                        <label htmlFor="primaryTechnicalskills">Secondary Technical Skills</label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="secondaryTechnicalskills"
                                            name="secondaryTechnicalskills"
                                            value={employeeData.secondaryTechnicalskills}
                                            onChange={handleInputChange}
                                            placeholder="Secondary Technologies"

                                        />
                                    </div>
                                </div>
                                    
                                <div className="row mb-4">
                                    <div className='col-md-6'>
                                        <label htmlFor="primaryTechnicalskills">Other Technical Skills</label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="otherTechnicalskills"
                                            name="otherTechnicalskills"
                                            value={employeeData.otherTechnicalskills}
                                            onChange={handleInputChange}
                                            placeholder="Other Technologies"

                                        />
                                    </div>

                                    {/* Certifications */}
                                    <div className="col-md-6">
                                        <label htmlFor="Certifications">Certifications</label>
                                        <input autocomplete="off"
                                            type="text"
                                            className="form-control"
                                            id="certifications"
                                            name="certifications"
                                            value={employeeData.certifications}
                                            onChange={handleInputChange}
                                            placeholder="Enter certifications"

                                        />
                                    </div>
                                </div>


                                {/* Onsite/offShore */}
                                <div className="row mb-4">
                                    <div className='col-md-4'>
                                        <label>Onsite/offShore <span style={{ color: 'red' }}>*</span></label>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="radio"
                                                className="form-check-input"
                                                id="onsite"
                                                name="onsiteOffshore"
                                                value="Onsite"
                                                checked={employeeData.onsiteOffshore === 'Onsite'}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="onsite">Onsite</label>
                                        </div>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="radio"
                                                className="form-check-input"
                                                id="offshore"
                                                name="onsiteOffshore"
                                                value="Offshore"
                                                checked={employeeData.onsiteOffshore === 'Offshore'}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="offshore">Offshore</label>
                                        </div>
                                    </div>

                                    {/* Background Verification */}
                                    <div className='col-md-4'>
                                        <label>Background Verification <span style={{ color: 'red' }}>*</span></label>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="radio"
                                                className="form-check-input"
                                                id="yes"
                                                name="backgroundVerification"
                                                value="true"
                                                checked={employeeData.backgroundVerification === 'true'}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="backgroundVerification">Yes</label>
                                            {/* Document Upload Field */}
                                            {employeeData.backgroundVerification === 'true' && (
                                                <div className='col-md-6'>
                                                    <label>Upload Document:</label>
                                                    <input autocomplete="off"
                                                        type="file"
                                                        ref={fileInputRefs.backgroundVerificationDoc}
                                                        accept=".pdf,.doc,.docx"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileSelect(e, 'backgroundVerificationDoc')}
                                                        required
                                                    />
                                                    <div>
                                                        <button type="button" onClick={() => fileInputRefs.backgroundVerificationDoc.current.click()} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                    </div>
                                                    {backgroundVerificationDocSizeError && <p style={{ color: 'red' }}>{backgroundVerificationDocSizeError}</p>}
                                                    {/* Display Uploaded Document */}
                                                    {backgroundVerificationDoc && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{backgroundVerificationDoc.name}</p>
                                                            <span onClick={() => {
                                                                setBackgroundVerificationDoc(null);
                                                                // Reset file input
                                                                if (fileInputRefs.backgroundVerificationDoc.current) {
                                                                    fileInputRefs.backgroundVerificationDoc.current.value = '';
                                                                }
                                                            }}>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="radio"
                                                className="form-check-input"
                                                id="no"
                                                name="backgroundVerification"
                                                value="false"
                                                checked={employeeData.backgroundVerification === 'false'}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="backgroundVerification">No</label>
                                        </div>
                                    </div>
                                    {/* statutory */}
                                    <div className="col-md-4">
                                        <label>Other Benefits </label>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="checkbox"
                                                className="form-check-input"
                                                id="uitf"
                                                name="UITF"
                                                checked={employeeData.statutory.includes('UITF')}
                                                onChange={handleStatutoryChange}

                                            />
                                            <label className="form-check-label" htmlFor="uitf">UITF</label>

                                        </div>
                                        <div className="form-check">
                                            {/* Insurance */}
                                            <input autocomplete="off"
                                                type="checkbox"
                                                className="form-check-input"
                                                id="insurance"
                                                name="Insurance"
                                                checked={employeeData.statutory.includes('Insurance')}
                                                onChange={handleStatutoryChange}

                                            />
                                            <label className="form-check-label" htmlFor="insurance">Insurance</label>
                                        </div>
                                    </div>

                                </div>



                                <div className='row mb-4'>
                                    {/* Employee Status */}

                                    <div className='col-md-4 '>
                                        <label>Employee Status <span style={{ color: 'red' }}>*</span></label>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="radio"
                                                className="form-check-input"
                                                id="working"
                                                name="employeeStatus"
                                                value="Working"
                                                checked={employeeData.employeeStatus === 'Working'}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="working">Working</label>
                                        </div>
                                        <div className="form-check">
                                            <input autocomplete="off"
                                                type="radio"
                                                className="form-check-input"
                                                id="relieved"
                                                name="employeeStatus"
                                                value="Relieved"
                                                checked={employeeData.employeeStatus === 'Relieved'}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="relieved">Relieved</label>
                                        </div>
                                        {employeeData.employeeStatus === 'Relieved' && (

                                            <div className="form-group">
                                                <label htmlFor="relievedDate">Relieved Date:<span style={{ color: 'red' }}>*</span></label>
                                                <div>
                                                    <DatePicker
                                                        selected={employeeData.relievedDate}
                                                        onChange={(date) => setEmployeeData({ ...employeeData, relievedDate: date })}
                                                        dateFormat="dd/MM/yyyy"
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        yearDropdownItemNumber={15}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                        )}
                                    </div>

                                    {/*  Status */}

                                    {employeeData.employeeStatus === 'Working' && (
                                        <div className='col-md-4'>
                                            <label>Status <span style={{ color: 'red' }}>*</span></label>
                                            <div className="form-check">
                                                <input autocomplete="off"
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="active"
                                                    name="status"
                                                    value="Active"
                                                    checked={employeeData.status === 'Active'}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="active">Active</label>
                                            </div>
                                            <div className="form-check">
                                                <input autocomplete="off"
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="inactive"
                                                    name="status"
                                                    value="Inactive"
                                                    checked={employeeData.status === 'Inactive'}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="inactive">Inactive</label>
                                            </div>
                                        </div>
                                    )}
                                    {employeeData.status == 'Inactive' && (
                                        <div className='col-md-3'>
                                            <label htmlFor="inactiveReason">Enter Inactive Reason</label>
                                            <input autocomplete="off"
                                                type="text"
                                                className="form-control"
                                                id="inActiveReason"
                                                name="inActiveReason"
                                                value={employeeData.inActiveReason}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    )}
                                    {employeeData.status == 'Active' && (
                                        <div className='col-md-3'>

                                            <label htmlFor="workingType">Select Working Type<span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                className="form-control"
                                                class="form-select"
                                                id="workingType"
                                                name="workingType"
                                                value={workingType}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Working Type</option>
                                                <option value="WFH">WFH</option>
                                                <option value="WFO">WFO</option>
                                                <option value="Hybrid">Hybrid</option>
                                            </select>
                                        </div>

                                    )}
                                    {/* onboarding */}
                                    <div className="col-md-4">

                                        <div>
                                            <label>Induction & Onboarding  <span style={{ color: 'red' }}>*</span></label>
                                            <div className="form-check">
                                                <input autocomplete="off"
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="yes"
                                                    name="inductionOnboarding"
                                                    value="true"
                                                    checked={employeeData.inductionOnboarding === 'true'}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="inductionOnboarding">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input autocomplete="off"
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="no"
                                                    name="inductionOnboarding"
                                                    value="false"
                                                    checked={employeeData.inductionOnboarding === 'false'}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="inductionOnboarding">
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                    </div>



                                </div>

                                <hr style={{ width: '80%', margin: '20px auto', backgroundColor: '#333' }} />

                                <h3 className='text-center'>Upload Documents</h3>
                                {errorMessageDoc && (
                                    <div style={{ color: "red" }}>
                                        {errorMessageDoc}
                                    </div>
                                )}
                                {/*upload docs*/}
                                <div>
                                    <div className='row'>
                                        {/*photo*/}
                                        {previewImages.length === 0 && (
                                            <div className='col-md-4 mt-2 mb-4'>
                                                <label>Profile Photo <span style={{ color: 'red' }}>*</span></label>
                                                <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                    <input autocomplete="off"
                                                        type="file"
                                                        ref={fileInputRefs.profilePhoto}
                                                        accept=".png, .jpg, .jpeg"
                                                        name="profilePhoto"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileSelect(e, 'profilePhoto')}

                                                    />
                                                    <button type="button" onClick={() => uploadClick('profilePhoto')} className="btn bg-primary text-white">
                                                        + Upload
                                                    </button>
                                                    <span style={{ marginLeft: '10px', color: 'red', minWidth: '200px' }}>
                                                        {profileFileSizeError && <p>{profileFileSizeError}</p>}
                                                        {profileFileTypeError && <p>{profileFileTypeError}</p>}
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                        {/* Display Profile Photo */}
                                        {previewImages.length > 0 && (
                                            <div className='col-md-4 mt-2 mb-4'>
                                                <label>Profile Photo <span style={{ color: 'red' }}>*</span></label>
                                                <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                    <input autocomplete="off"
                                                        type="file"
                                                        ref={fileInputRefs.profilePhoto}
                                                        accept=".png, .jpg, .jpeg"
                                                        name="profilePhoto"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileSelect(e, 'profilePhoto')}

                                                    />
                                                    <button type="button" onClick={() => uploadClick('profilePhoto')} className="btn bg-primary text-white">
                                                        + Upload
                                                    </button>
                                                    <span style={{ marginLeft: '10px', color: 'red', minWidth: '200px' }}>
                                                        {profileFileSizeError && <p>{profileFileSizeError}</p>}
                                                        {profileFileTypeError && <p>{profileFileTypeError}</p>}
                                                    </span>
                                                </div>
                                                {/* Display Uploaded Profile Photo */}
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {previewImages.map((image) => (
                                                        <div key={image.id} style={{ margin: '10px' }}>
                                                            {/* Check if the image belongs to the selected documentType */}
                                                            {image.documentType === 'profilePhoto' && (
                                                                <div>
                                                                    {image.file.type.startsWith('image') ? (
                                                                        <div>
                                                                            <img
                                                                                src={image.preview}
                                                                                alt={`Preview ${image.id}`}
                                                                                style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
                                                                                onClick={() => openImageModal(image)}
                                                                            />
                                                                            <span onClick={() => handleDelete1(image.id)}>
                                                                                <i className="fas fa-trash" style={{ marginLeft: '10px' }}></i>
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <div>Preview not available for non-image files</div>
                                                                            <button onClick={() => handleDelete1(image.id)}>Delete</button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {/*resume*/}
                                        <div className='col-md-4 mt-2 mb-4'>
                                            <label>Resume </label>
                                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                <input autocomplete="off"
                                                    type="file"
                                                    ref={fileInputRefs.resume}
                                                    accept=".pdf,.doc,.docx"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, 'resume')}
                                                    
                                                />
                                                <button type="button" onClick={() => uploadClick('resume')} className="btn bg-primary text-white">
                                                    + Upload
                                                </button>
                                                <span style={{ color: 'red', minWidth: '200px' }}>
                                                    {resumeFileSizeError && <p>{resumeFileSizeError}</p>}
                                                    {resumeFileTypeError && <p>{resumeFileTypeError}</p>}
                                                </span>
                                            </div>
                                            {/* Display Uploaded Resume */}
                                            {resume && (
                                                <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                    <p style={{ marginRight: '10px', marginBottom: 5 }}>{resume.name}</p>
                                                    <span onClick={() => {
                                                        setResume(null);
                                                        // Reset file input
                                                        if (fileInputRefs.resume.current) {
                                                            fileInputRefs.resume.current.value = '';
                                                        }
                                                    }}>
                                                        <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                    </span>
                                                </div>
                                            )}

                                        </div>


                                        {/*NDA docs*/}
                                        <div className='col-md-4 mt-2 mb-4'>
                                            <label>NDA </label>
                                            <input autocomplete="off"
                                                type="file"
                                                ref={fileInputRefs.nda}
                                                accept=".pdf,.doc,.docx"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileSelect(e, 'nda')}
                                            />
                                            {/* Upload Button and Error Message */}
                                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                <button type="button" onClick={() => uploadClick('nda')} className="btn bg-primary text-white">
                                                    + Upload
                                                </button>
                                                <span style={{ color: 'red', minWidth: '200px' }}>
                                                    {NDAFileSizeError && <p>{NDAFileSizeError}</p>}
                                                    {NDAFileTypeError && <p>{NDAFileTypeError}</p>}
                                                </span>
                                            </div>

                                            {/* Display Uploaded NDA File */}
                                            {NDAFile && (
                                                <div style={{ marginTop: '10px' }}>
                                                    <p>{NDAFile.name}
                                                        <span onClick={() => {
                                                            setNDAFile(null);
                                                            if (fileInputRefs.nda.current) {
                                                                fileInputRefs.nda.current.value = '';

                                                            }
                                                        }}>
                                                            <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className='row'>
                                        {/*EDU docs*/}
                                        <div className="col-md-4 mt-2 mb-4">
                                            <label>Educational Documents</label>
                                            <div>
                                                <input autocomplete="off"
                                                    type="file"
                                                    ref={fileInputRefs.educationalDocuments}
                                                    accept=".png, .pdf, .doc, .docx"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, 'educationalDocuments')}
                                                    multiple
                                                />
                                                <div>
                                                    <button type="button" onClick={() => uploadClick('educationalDocuments')} className="btn bg-primary text-white">
                                                        + Upload
                                                    </button>
                                                </div>
                                                <span style={{ color: 'red', minWidth: '200px' }}>
                                                    {EduFileSizeError && <p>{EduFileSizeError}</p>}
                                                    {EduFileTypeError && <p>{EduFileTypeError}</p>}
                                                </span>
                                            </div>

                                            {EduFiles.map((file, index) => (
                                                <div key={index}>
                                                    <p>{file.name}
                                                        <span onClick={() => {

                                                            handleDelete(index, 'educationalDocuments')
                                                            if (fileInputRefs.educationalDocuments.current) {
                                                                fileInputRefs.educationalDocuments.current.value = '';

                                                            }
                                                        }
                                                        }>
                                                            <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            ))}


                                        </div>

                                        {/*paySlips docs*/}

                                        <div className='col-md-4 mt-2 mb-4'>
                                            <div>

                                                <label>paySlips </label>
                                                <input autocomplete="off"
                                                    type="file"
                                                    ref={fileInputRefs.paySlips}
                                                    accept=".png,.pdf,.doc,.docx"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, 'paySlips')}
                                                    multiple
                                                />
                                                <div>
                                                    <button type="button" onClick={() => uploadClick('paySlips')} className="btn bg-primary text-white">
                                                        + Upload
                                                    </button>
                                                    <p>(For the Experienced employees)</p>
                                                </div>
                                                <span style={{ color: 'red', minWidth: '200px' }}>
                                                    {PayslipFileSizeError && <p>{PayslipFileSizeError}</p>}
                                                     {PayslipFileTypeError && <p>{PayslipFileTypeError}</p>}
                                                </span>
                                            </div>

                                            {PayslipFile.map((file, index) => (
                                                <div key={index}>
                                                    <p>{file.name}
                                                        <span onClick={() => {
                                                            handleDelete(index, 'paySlips')
                                                            if (fileInputRefs.paySlips.current) {
                                                                fileInputRefs.paySlips.current.value = '';

                                                            }
                                                        }
                                                        }>
                                                            <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            ))}


                                        </div>
                                        {/* other documents */}
                                        <div className='col-md-4 mt-2 mb-4'>
                                            <label>Other Documents</label>
                                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                <input autocomplete="off"
                                                    type="file"
                                                    ref={fileInputRefs.otherDocuments}
                                                    accept=".png,.pdf,.doc,.docx"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, 'otherDocuments')}
                                                    multiple
                                                //  required
                                                />
                                                <button type="button" onClick={() => uploadClick('otherDocuments')} className="btn bg-primary text-white">
                                                    + Upload
                                                </button>
                                                <span style={{ color: 'red', minWidth: '200px' }}>
                                                    {otherDocumentsSizeError && <p>{otherDocumentsSizeError}</p>}
                                                    {otherDocumentsTypeError && <p>{otherDocumentsTypeError}</p>}
                                                </span>
                                            </div>
                                            {/* Display Uploaded otherDocuments */}
                                            {otherDocuments.map((file, index) => (
                                                <div key={index}>
                                                    <p>{file.name}
                                                        <span onClick={() => {

                                                            handleDelete(index, 'otherDocuments')
                                                            if (fileInputRefs.otherDocuments.current) {
                                                                fileInputRefs.otherDocuments.current.value = '';

                                                            }
                                                        }
                                                        }>
                                                            <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                        </span>
                                                    </p>

                                                    {/* <span onClick={() => {
                                                        setotherDocuments(null);
                                                        if (fileInputRefs.otherDocuments.current) {
                                                            fileInputRefs.otherDocuments.current.value = '';
                                                        }
                                                    }}>
                                                        <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                    </span> */}
                                                </div>
                                            ))}
                                        </div>




                                        {/* Based on country the upload documents will change */}

                                        {/* if the country is dubai */}
                                        {employeeData.country === 'Dubai' && (
                                            <div className='row'>
                                                {/* Work visa */}
                                                <div className='col-md-4 mt-2 mb-4'>
                                                    <label>Work visa</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.workVisa}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'workVisa')}
                                                            multiple
                                                        //   required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('workVisa')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {WorkvisaSizeError && <p>{WorkvisaSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {workVisa.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'workVisa')
                                                                    if (fileInputRefs.workVisa.current) {
                                                                        fileInputRefs.workVisa.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Work Permit */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Work Permit</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.workPermit}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'workPermit')}
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('workPermit')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {WorkPermitSizeError && <p>{WorkPermitSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {workPermit.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'workPermit')
                                                                    if (fileInputRefs.workPermit.current) {
                                                                        fileInputRefs.workPermit.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Residance Visa */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Residence Visa</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.residanceVisa}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'residanceVisa')}
                                                            multiple
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('residanceVisa')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {ResidanceVisaSizeError && <p>{ResidanceVisaSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {residanceVisa.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'residanceVisa')
                                                                    if (fileInputRefs.residanceVisa.current) {
                                                                        fileInputRefs.residanceVisa.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Emriates ID */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Emriates ID</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.emriatesID}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'emriatesID')}
                                                            multiple
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('emriatesID')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {EmriatesIDSizeError && <p>{EmriatesIDSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {emriatesID.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'emriatesID')
                                                                    if (fileInputRefs.emriatesID.current) {
                                                                        fileInputRefs.emriatesID.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Labour Card */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Labour Card</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.labourCard}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'labourCard')}
                                                            multiple
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('labourCard')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {LabourCardSizeError && <p>{LabourCardSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {labourCard.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'labourCard')
                                                                    if (fileInputRefs.labourCard.current) {
                                                                        fileInputRefs.labourCard.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}




                                        {/* upload documents for US  */}
                                        {/* passport copy */}
                                        {employeeData.country === 'US' && (

                                            <div className='row'>
                                                <div className='col-md-4 mb-4'>
                                                    <label>Passport copy</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.passportCopy}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'passportCopy')}
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('passportCopy')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {passportCopySizeError && <p>{passportCopySizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {passportCopy && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{passportCopy.name}</p>
                                                            <span onClick={() => {
                                                                setpassportCopy(null)
                                                                if (fileInputRefs.passportCopy.current) {
                                                                    fileInputRefs.passportCopy.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* I-797 */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>I-797 card </label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.i_797}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'i_797')}
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('i_797')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {i_797SizeError && <p>{i_797SizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {i_797 && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{i_797.name}</p>
                                                            <span onClick={() => {
                                                                seti_797(null)
                                                                if (fileInputRefs.i_797.current) {
                                                                    fileInputRefs.i_797.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* I-94 card */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>I-94 card </label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.i_94Card}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'i_94Card')}
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('i_94Card')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {i_94CardSizeError && <p>{i_94CardSizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {i_94Card && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{i_94Card.name}</p>
                                                            <span onClick={() => {

                                                                seti_94Card(null)
                                                                if (fileInputRefs.i_94Card.current) {
                                                                    fileInputRefs.i_94Card.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* Experience letter */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>Experience letter</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.experienceLetter}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'experienceLetter')}
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('experienceLetter')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {experienceLetterSizeError && <p>{experienceLetterSizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {experienceLetter && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{experienceLetter.name}</p>
                                                            <span onClick={() => {
                                                                setexperienceLetter(null)

                                                                if (fileInputRefs.experienceLetter.current) {
                                                                    fileInputRefs.experienceLetter.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* reliving letter */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>Reliving letter</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.relievingLetter}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'relievingLetter')}
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('relievingLetter')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {relievingLetterSizeError && <p>{relievingLetterSizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {relievingLetter && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{relievingLetter.name}</p>
                                                            <span onClick={() => {
                                                                setrelievingLetter(null)
                                                                if (fileInputRefs.relievingLetter.current) {
                                                                    fileInputRefs.relievingLetter.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* Driving license */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>Driving license</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.drivingLicense}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'drivingLicense')}
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('drivingLicense')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {drivingLicenseSizeError && <p>{drivingLicenseSizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {drivingLicense && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{drivingLicense.name}</p>
                                                            <span onClick={() => {
                                                                setdrivingLicense(null)
                                                                if (fileInputRefs.drivingLicense.current) {
                                                                    fileInputRefs.drivingLicense.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* SSN number or copy */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>SSN copy</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.ssnNumber}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'ssnNumber')}
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('ssnNumber')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {ssnNumberSizeError && <p>{ssnNumberSizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {ssnNumber && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{ssnNumber.name}</p>
                                                            <span onClick={() => {
                                                                setssnNumber(null)
                                                                if (fileInputRefs.ssnNumber.current) {
                                                                    fileInputRefs.ssnNumber.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* H4 copy */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>H4 copy</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.h4Copy}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'h4Copy')}
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('h4Copy')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {h4CopySizeError && <p>{h4CopySizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {h4Copy && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{h4Copy.name}</p>
                                                            <span onClick={() => {
                                                                seth4Copy(null)
                                                                if (fileInputRefs.h4Copy.current) {
                                                                    fileInputRefs.h4Copy.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                                {/* GC card */}

                                                <div className='col-md-4 mb-4'>
                                                    <label>GC card</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.gcCard}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'gcCard')}
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('gcCard')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {gcCardSizeError && <p>{gcCardSizeError}</p>}

                                                        </span>
                                                    </div>

                                                    {gcCard && (
                                                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ marginRight: '10px', marginBottom: 5 }}>{gcCard.name}</p>
                                                            <span onClick={() => {
                                                                setgcCard(null)
                                                                if (fileInputRefs.gcCard.current) {
                                                                    fileInputRefs.gcCard.current.value = '';
                                                                }
                                                            }
                                                            }>
                                                                <i className="fas fa-trash" style={{ cursor: 'pointer', verticalAlign: 'top' }}></i>
                                                            </span>
                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                        )}

                                        {employeeData.country === 'Canada' && (
                                            <div className='row'>
                                                {/* Work visa */}
                                                <div className='col-md-4 mt-2 mb-4'>
                                                    <label>Work visa</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.workVisa}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'workVisa')}
                                                            multiple
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('workVisa')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {WorkvisaSizeError && <p>{WorkvisaSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {workVisa.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'workVisa')
                                                                    if (fileInputRefs.workVisa.current) {
                                                                        fileInputRefs.workVisa.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Work Permit */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Work Permit</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.workPermit}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'workPermit')}
                                                            multiple
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('workPermit')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {WorkPermitSizeError && <p>{WorkPermitSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {workPermit.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'workPermit')
                                                                    if (fileInputRefs.workPermit.current) {
                                                                        fileInputRefs.workPermit.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Residance Visa */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Residence Visa</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.residanceVisa}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'residanceVisa')}
                                                            multiple
                                                        //  required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('residanceVisa')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {ResidanceVisaSizeError && <p>{ResidanceVisaSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {residanceVisa.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'residanceVisa')
                                                                    if (fileInputRefs.residanceVisa.current) {
                                                                        fileInputRefs.residanceVisa.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Labour Card */}
                                                <div className='col-md-4 mb-4'>
                                                    <label>Labour Card</label>
                                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '250px' }}>
                                                        <input autocomplete="off"
                                                            type="file"
                                                            ref={fileInputRefs.labourCard}
                                                            accept=".png,.pdf,.doc,.docx"
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => handleFileSelect(e, 'labourCard')}
                                                            multiple
                                                        // required
                                                        />
                                                        <button type="button" onClick={() => uploadClick('labourCard')} className="btn bg-primary text-white">
                                                            + Upload
                                                        </button>
                                                        <span style={{ color: 'red', minWidth: '200px' }}>
                                                            {LabourCardSizeError && <p>{LabourCardSizeError}</p>}
                                                        </span>
                                                    </div>

                                                    {labourCard.map((file, index) => (
                                                        <div key={index}>
                                                            <p>{file.name}
                                                                <span onClick={() => {
                                                                    handleDelete(index, 'labourCard')
                                                                    if (fileInputRefs.labourCard.current) {
                                                                        fileInputRefs.labourCard.current.value = '';
                                                                    }
                                                                }
                                                                }>
                                                                    <i className="fas fa-trash" style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    ))}

                                                </div>

                                            </div>

                                        )}
                                        {/* Modal for Image Preview */}
                                        <Modal isOpen={selectedImage !== null} onRequestClose={closeImageModal}>
                                            {selectedImage && selectedImage.file && selectedImage.file.type.startsWith('image') ? (
                                                <img
                                                    src={selectedImage.preview}
                                                    alt={`Preview ${selectedImage.id}`}
                                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                />
                                            ) : (
                                                <div>Preview not available for non-image files</div>
                                            )}
                                            <button
                                                className="btn bg-primary text-white"
                                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                                                onClick={closeImageModal}
                                            >
                                                Close
                                            </button>
                                        </Modal>
                                    </div>

                                </div>


                                {/* <p style={{ color: 'red' }}> (*) Mandatory Fields</p> */}
                                <div className='row pt-3'>
                                    <div className='col-md-6'>
                                        <button type="submit" className="btn btn-block bg-primary text-white" style={{ margin: "5px" }}>Submit</button>
                                    </div>
                                    <div className='col-md-6'>
                                        <button type="submit" className="btn btn-block bg-primary text-white" style={{ margin: "5px" }} onClick={handleReset} >Reset</button>
                                    </div>
                                    {/* onClick={handleReset} */}
                                </div>
                                {/* Submit Button */}

                            </form>
                        </div>
                    </div>
                </div>
            </div >
            {/* Model popup */}
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            Do you want to update with the previous one?
                        </div>
                        <div class="modal-footer justify-content-center">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-primary">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >

    );
};

export default EmployeeRegistrationForm;
